import API from '../modules/API';
import ImmAddress from './imm-address';
import ImmContactList from './imm-contact-list';

const WYSIWYGinsts = {
	challenges: null,
	programmation: null,
	contexte: null,
	desc: null,
	comment: null,
	link: null,
};

export default {
	name: 'ImmFormBreve',
	template: require('./imm-form-breve.html'),
	components: {ImmAddress, ImmContactList},
	props: ['modelValue'],
	data() {
		return {
			publicFields: [
				'object',
				'project',
				'area',
			],
			_breve: {
				onlySubscriber: false,
				status: 'waiting',
			},
			formError: null,
			loadingCommit: false,
			addressSuggestions: [],
			addressSearchDisabled: false,
			// file upload
			fileName: '',
			breve: null,
			// calendar
			calendarError: null,
		};
	},
	computed: {
		isEdit() {
			return this.isEmpty(this.modelValue) === false;
		},
		published: {
			get() {
				return this.breve.status === 'published';
			},
			set(v) {
				this.breve.status = v === true ? 'published' : 'waiting';
			},
		}
	},
	async mounted() {
		this.breve = await this.getBreve();
		this.addressSearchDisabled = this.isEmpty(this.breve.address_line1) === false && this.breve.address_line1.length > 0;

		window.setTimeout(async() => {
			for (let type of Object.keys(WYSIWYGinsts)) {
				if (WYSIWYGinsts[type] === null) {
					const instances = await window.tinymce.init({
						selector: '#textarea-wysiwyg-' + type,
						...this.Config.tinymceOptions,
					});
					WYSIWYGinsts[type] = instances[0];
				}

				WYSIWYGinsts[type].setContent('');
				if (this.isEmpty(this.breve[type]) === false) {
					WYSIWYGinsts[type].setContent(this.breve[type]);
				}
			}

		}, 100);
	},
	async unmounted() {
		for (let type of Object.keys(WYSIWYGinsts)) {
			WYSIWYGinsts[type].destroy();
			WYSIWYGinsts[type] = null;
		}
	},
	methods: {
		getBreve() {
			const breve = (this.isEdit === true) ? this.modelValue : this._breve;
			if (this.isEmpty(breve.contacts) === true) {
				breve.contacts = [];
			}
			return breve;
		},
		async onBreveAdd() {
			this.formError = null;
			this.loadingCommit = true;
			try {
				// WYSIWYG code
				for (let type of Object.keys(WYSIWYGinsts)) {
					const html = WYSIWYGinsts[type].getContent();
					this.breve[type] = html;
				}

				if (this.isEdit === true) {
					await API.editBreve(this.modelValue.id, this.breve);
				} else {
					await API.addBreve(this.breve);
				}
				this.$router.replace('/profil/breves/');
			} catch (exception) {
				this.formError = exception;
			} finally {
				this.loadingCommit = false;
			}
		},
		handleFileChange(event) {
			this.fileName = '';
			const file = event.files[0];
			if (this.isEmpty(file) === true) {
				return;
			}

			this.fileName = file.name;

			const reader = new FileReader();
			reader.readAsDataURL(file);

			reader.onload = (e) => {
				// base64 image encoding
				this.breve.imageAd = e.target.result;
			};
		},
		addNewCalendarItem(event) {
			event.preventDefault();

			if (this.isEmpty(this.$refs['new-calendar-label'].value.trim()) === true) {
				this.calendarError = 'Label invalide';
				return;
			}
			if (this.isEmpty(this.$refs['new-calendar-value'].value.trim()) === true) {
				this.calendarError = 'Date invalide';
				return;
			}

			const item = {
				label: this.$refs['new-calendar-label'].value.trim(),
				value: this.$refs['new-calendar-value'].value.trim(),
			};

			this.$refs['new-calendar-label'].value = '';
			this.$refs['new-calendar-value'].value = '';

			if (this.isEmpty(this.breve.calendar) === true) {
				this.breve.calendar = [];
			}
			this.breve.calendar.push(item);

			this.calendarError = null;
		},
		removeNewCalendarItem(event, idx) {
			event.preventDefault();

			//console.log('removeNewCalendarItem', idx, this.breve.calendar[idx]);
			this.breve.calendar = this.breve.calendar.filter((i, idxC) => idx !== idxC);
		}
	},
};
